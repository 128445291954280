<script>
import { getSettingRequest } from "../../_shared/api";
import { setSetting, getSetting } from "../../_shared/constants";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Intranet Medviu"
    // all titles will be injected into this template
  },
  data() {
    return {
      logo: require("../../assets/logo_medviu.svg"),
      setting: {}
    };
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("locale", this.$i18n.locale);
      this.$router.go(0);
    },
    classForLocale(locale) {
      if (this.$i18n.locale == locale) return "current";

      return "";
    }
  },
  mounted() {
    this.$gtag.pageview({ page_path: this.$route.path });
  },
  computed: {},
  created() {
    getSettingRequest().then((response) => {
      setSetting(response.data.data);

      this.setting = getSetting();
      if (localStorage.getItem("locale") == null) {
        this.$i18n.locale = response.data.data.language;
      }
    });
  }
};
</script>

<template>
  <div>
    <link
      rel="stylesheet"
      :href="setting.custom_css_file_url"
      v-if="setting.use_branding && setting.custom_css_file_url"
    />

    <div class="header">
      <div class="header-wrapper">
        <div class="col-wrapper">
          <div class="left-col">
            <div
              v-if="setting.use_branding && setting.company_logo_url"
              class="header__logo"
            >
              <img :src="setting.company_logo_url" />
            </div>
            <div v-else class="header__logo">
              <img :src="logo" style="display:block; height:56px;"/>
            </div>
          </div>

          <div class="right-col">
            <div class="header__links">
              <router-link
                :to="$_routesMixin_companyUrl('media-mirror')"
                class="btn"
              >
                {{ $t("intranet.navigation.media_mirror") }}
              </router-link>
              <router-link :to="$_routesMixin_companySearchUrl(1)" class="btn">
                {{ $t("intranet.navigation.search") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contentsection content-wrapper full-coll">
      <slot></slot>
    </div>
    <footer>
      <div class="footer-logo-wrapper">
        <span>by</span>
        <div class="footer-logo"></div>
      </div>
      <div class="footer-languages">
        <b-link @click="changeLocale('de')" :class="classForLocale('de')"
          >DE</b-link
        >
        <b-link @click="changeLocale('fr')" :class="classForLocale('fr')">
          FR
        </b-link>
        <b-link @click="changeLocale('it')" :class="classForLocale('it')">
          IT
        </b-link>
      </div>
    </footer>
  </div>
</template>
